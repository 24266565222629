frappe.provide("frappe.uid");

if (!window.is_404) {
    
    let browser = frappe.utils.get_browser();
	let query_params = frappe.utils?.get_query_params?.() || {};

    frappe.uid.fpPromise
        .then(fp => fp.get())
        .then(result => {

            frappe.uid.data = result;
            
            frappe.call("frappe.website.doctype.web_page_view.web_page_view.make_view_log", {
                referrer: document.referrer,
                browser: browser.name,
                version: browser.version,
                user_tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
                source: query_params.source,
                medium: query_params.medium,
                campaign: query_params.campaign,
                vendor: frappe.uid.data.components.platform.value,
                system_os: frappe.uid.data.components.osCpu.value,
                visitor_id: frappe.uid.data.visitorId || frappe.tools.get_persistent('x_uid'),
                data: JSON.stringify({
                    'Plugins':frappe.uid.data.components.plugins,
                    'Audio':frappe.uid.data.components.audio,
                    'ScreenFrames':frappe.uid.data.components.ScreenFrames,
                    'Resolution':frappe.uid.data.components.screenResolution.value
                })
            })
            
            if (frappe.tools.get_persistent('x_uid') == null || frappe.tools.get_persistent('x_uid') != frappe.uid.data.visitorId ){
                frappe.tools.save_persistent('x_uid', frappe.uid.data.visitorId);
            }
            
        })
    

}

