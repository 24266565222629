frappe.provide("frappe.analytics");

if (typeof frappe.analytics !== 'undefined' && typeof frappe.analytics.google_analytics_id !== 'undefined') {
    // Google Analytics
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
            
    ga('create', frappe.analytics.google_analytics_id , 'auto');
    if (frappe.analytics.google_analytics_anonymize_ip) {
        ga('set', 'anonymizeIp', true);
    }
    ga('send', 'pageview');
    // End Google Analytics

    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', frappe.analytics.google_analytics_id);

}