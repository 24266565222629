export default class Tab {
	constructor(layout, df, frm, tab_link_container, tabs_content) {
		this.layout = layout;
		this.df = df || {};
		this.frm = frm;
		this.doctype = this.frm?.doctype ?? this.df.parent;
		this.label = this.df && this.df.label;
		this.icon = this.get_icon(this.df.fieldname);
		this.tab_link_container = tab_link_container;
		this.tabs_content = tabs_content;
		this.make();
		this.setup_listeners();
		this.refresh();
	}
	make_icon_element(value){
		return `<svg class="es-icon icon-sm">
					<use href="#${value}">
					</use>
				</svg>` || false;
	}
	get_icon(fieldname){
		let icon_sets = {
			'activities_tab':'es-calendar-ic',
			'details_tab': 'es-dots-vertical-ic',
			'basic_details_tab': 'es-dots-vertical-ic',
			'dashboard_tab':'es-shared-ic',
			'connections_tab':'es-shared-ic',
			'notes_tab':'es-note-ic',
			'tasks_tab':'es-clock-ic',
			'manufacturing': 'es-gear-ic',
			'quality_tab': 'es-quality-ic',
			'accounting':'es-accounting-ic',
			'accounting_tab':'es-accounting-ic',
			'more_info_tab': 'es-zap-info-ic',
			'payments_tab': 'es-credit-card-ic',
			'contact_and_address_tab':'es-location-ic',
			'address_and_contact_tab':'es-location-ic',
			'contact_info':'es-location-ic',
			'contact_details':'es-location-ic',
			'terms_tab':'es-terms-ic',
			'payment_schedule_section':'es-terms-ic',
			'salary_information':'es-salary-ic',
			'attendance_and_leave_details':'es-attendance-ic',
			'exit': 'es-leave-ic',
			'employment_details': 'es-joining-ic',
			'settings_tab': 'es-gear-ic',
			'portal_users_tab': 'es-line-people',
			'sales_team_tab': 'es-solid-people',
			'tax_tab': 'es-tax-ic'
		}

		return this.make_icon_element(icon_sets[fieldname]) || this.df && this.make_icon_element(this.df.icon);
	}

	make() {
		const id = `${frappe.scrub(this.doctype, "-")}-${this.df.fieldname}`;
		this.tab_link = $(`
			<li class="nav-item">
				<a class="nav-link ${this.df.active ? "active" : ""}" id="${id}-tab"
					data-toggle="tab"
					data-fieldname="${this.df.fieldname}"
					href="#${id}"
					role="tab"
					aria-controls="${id}">
					${this.icon}
						${__(this.label)}
				</a>
			</li>
		`).appendTo(this.tab_link_container);

		this.wrapper = $(`<div class="tab-pane fade show ${this.df.active ? "active" : ""}"
			id="${id}" role="tabpanel" aria-labelledby="${id}-tab">`).appendTo(this.tabs_content);
	}

	refresh() {
		if (!this.df) return;

		// hide if explicitly hidden
		let hide = this.df.hidden || this.df.hidden_due_to_dependency;

		// hide if no read permission
		if (!hide && this.frm && !this.frm.get_perm(this.df.permlevel || 0, "read")) {
			hide = true;
		}

		if (!hide) {
			// show only if there is at least one visible section or control
			hide = true;
			if (
				this.wrapper.find(
					".form-section:not(.hide-control, .empty-section), .form-dashboard-section:not(.hide-control, .empty-section)"
				).length
			) {
				hide = false;
			}
		}

		this.toggle(!hide);
	}

	toggle(show) {
		this.tab_link.toggleClass("hide", !show);
		this.wrapper.toggleClass("hide", !show);
		this.tab_link.toggleClass("show", show);
		this.wrapper.toggleClass("show", show);
		this.hidden = !show;
	}

	show() {
		this.tab_link.show();
	}

	hide() {
		this.tab_link.hide();
	}

	add_field(fieldobj) {
		fieldobj.tab = this;
	}
	replace_field(fieldobj) {
		fieldobj.tab = this;
	}

	set_active() {
		this.tab_link.find(".nav-link").tab("show");
		this.wrapper.addClass("show");
		this.frm?.set_active_tab?.(this);
	}

	is_active() {
		return this.wrapper.hasClass("active");
	}

	is_hidden() {
		return this.wrapper.hasClass("hide") && this.tab_link.hasClass("hide");
	}

	setup_listeners() {
		this.tab_link.find(".nav-link").on("shown.bs.tab", () => {
			this?.frm.set_active_tab?.(this);
		});
	}
}
