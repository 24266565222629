frappe.provide("frappe.tools");

class CookieManager {

    constructor() {
        this.cookies = {};
        this.toDelete = [];
    }

    initCookies() {
        // Assuming you have frappe or a similar session handling mechanism in place
        if (!frappe?.local?.session?.sid) {
            return;
        }

        if (frappe.session.sid) {
            this.setCookie('sid', frappe.session.sid, {
                maxAge: this.getExpiryInSeconds(),
                httpOnly: true
            });
        }
    }

    setCookie(key, value, options = {}) {
        const {
            expires = null,
            secure = false,
            httpOnly = false,
            sameSite = 'Lax',
            maxAge = null
        } = options;

        const isSecure = secure || (window.location.protocol === 'https:');
        this.cookies[key] = {
            value: value,
            expires: expires,
            secure: isSecure,
            httpOnly: httpOnly,
            sameSite: sameSite,
            maxAge: maxAge
        };

        // Set the cookie immediately in the browser
        this._setCookieInDocument(key, value, {
            expires,
            secure: isSecure,
            httpOnly,
            sameSite,
            maxAge
        });
    }

    getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(nameEQ) === 0) {
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
            }
        }
        return null;
    }

    deleteCookie(toDelete) {
        if (!Array.isArray(toDelete)) {
            toDelete = [toDelete];
        }
        this.toDelete = this.toDelete.concat(toDelete);
    }

    flushCookies() {
        // Set cookies
        for (let [key, opts] of Object.entries(this.cookies)) {
            this._setCookieInDocument(key, opts.value, opts);
        }

        // Expire cookies to be deleted
        const expires = new Date();
        expires.setDate(expires.getDate() - 1); // Yesterday

        for (let key of this.toDelete) {
            this._setCookieInDocument(key, '', { expires });
        }
    }

    _setCookieInDocument(key, value, { expires, secure, httpOnly, sameSite, maxAge }) {
        let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

        if (expires) {
            cookieString += `; expires=${expires.toUTCString()}`;
        }

        if (maxAge) {
            cookieString += `; max-age=${maxAge}`;
        }

        if (secure) {
            cookieString += `; secure`;
        }

        if (httpOnly) {
            cookieString += `; HttpOnly`; // This may not be settable from client-side JavaScript
        }

        cookieString += `; SameSite=${sameSite}`;

        document.cookie = cookieString;
    }

    getExpiryInSeconds() {
        // Implement your own logic for calculating expiration in seconds
        const sessionDuration = 3600; // Example: 1 hour session
        return sessionDuration;
    }
}

Object.assign(frappe.tools, {
	get_persistent: function(key){
		let _persistent_ = localStorage.getItem(key);
		if (_persistent_){
			return _persistent_;
		}
		return null;
	},
	save_persistent: function(key,value){
		localStorage.setItem(key, value);
	},
    // CookieManager functionalities
    cookieManager: new CookieManager(),

    // Optional helper methods for cookies
    get_cookie: function(name) {
        return this.cookieManager.getCookie(name);
    },

    set_cookie: function(key, value, options) {
        this.cookieManager.setCookie(key, value, options);
    },

    delete_cookie: function(key) {
        this.cookieManager.deleteCookie(key);
    }

});
